.dataimg-badge-finish {background-image: url("https://img2.cncenter.cz/images/maminka-roku/dist/svg/badge-finish.svg"); background-repeat: no-repeat;}

.dataimg-badge-selection {background-image: url("https://img2.cncenter.cz/images/maminka-roku/dist/svg/badge-selection.svg"); background-repeat: no-repeat;}

.dataimg-bar-chart2 {background-image: url("https://img2.cncenter.cz/images/maminka-roku/dist/svg/bar-chart2.svg"); background-repeat: no-repeat;}

.dataimg-close {background-image: url("https://img2.cncenter.cz/images/maminka-roku/dist/svg/close.svg"); background-repeat: no-repeat;}

.dataimg-confirm {background-image: url("https://img2.cncenter.cz/images/maminka-roku/dist/svg/confirm.svg"); background-repeat: no-repeat;}

.dataimg-error {background-image: url("https://img2.cncenter.cz/images/maminka-roku/dist/svg/error.svg"); background-repeat: no-repeat;}

.dataimg-like {background-image: url("https://img2.cncenter.cz/images/maminka-roku/dist/svg/like.svg"); background-repeat: no-repeat;}

.dataimg-logo-maminka-roku-2019 {background-image: url("https://img2.cncenter.cz/images/maminka-roku/dist/svg/logo-maminka-roku-2019.svg"); background-repeat: no-repeat;}

.dataimg-logo-maminka-roku-2020 {background-image: url("https://img2.cncenter.cz/images/maminka-roku/dist/svg/logo-maminka-roku-2020.svg"); background-repeat: no-repeat;}

.dataimg-logo-maminka-roku {background-image: url("https://img2.cncenter.cz/images/maminka-roku/dist/svg/logo-maminka-roku.svg"); background-repeat: no-repeat;}

.dataimg-rotate {background-image: url("https://img2.cncenter.cz/images/maminka-roku/dist/svg/rotate.svg"); background-repeat: no-repeat;}

.dataimg-search {background-image: url("https://img2.cncenter.cz/images/maminka-roku/dist/svg/search.svg"); background-repeat: no-repeat;}